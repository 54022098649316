<template>
  <div>
      <div v-if="type == '4'" class="export">
          <div class="title">导出的数据</div>
          <div class="container">
              <div class="item">
                  <div class="left">扶世锐扶世锐扶世锐扶世锐扶世锐扶世锐扶世锐扶世锐扶世锐扶世锐</div>
                  <div class="right">13099205304130992053041309920530413099205304</div>
              </div>
          </div>
      </div>
      <div v-if="type == 'import'" class="export">
          <div class="title">导入的数据</div>
          <div class="container">
              <div class="item">
                  <div class="left">扶世锐扶世锐扶世锐扶世锐扶世锐扶世锐扶世锐扶世锐扶世锐扶世锐</div>
                  <div class="right">13099205304130992053041309920530413099205304</div>
              </div>
          </div>
      </div>
      <div v-if="type == '2'" class="edit">
          <div class="top">
              <div class="item">修改对象姓名:{{'五月天'}}</div>
              <div class="item">操作人:{{'五月天'}}</div>
              <div class="item">操作时间：{{'2021-3-13 14:20:30'}}</div>
          </div>
          <div class="table">
              <Table  :columns="talbeColumns" :loading="loading" :data="tableData"  height="480" border stripe></Table>
          </div>
      </div>
      <div v-if="type == '1'" class="add">
          <div class="title">新增的居民</div>
          <div class="container">
              <div class="item">
                   <div>居民姓名:{{'五月天'}}</div>
                   <div>居民手机:{{'13256378909'}}</div>
              </div>
              <div class="item">
                   <div>居民姓名:{{'五月天'}}</div>
                   <div>居民手机:{{'13256378909'}}</div>
              </div>
          </div>
      </div>
      <div v-if="type == 'send'" class="send">
          <div class="title">消息接收人</div>
          <div class="container">
              扶世锐 、黄世佳
          </div>
          <div class="title" style="margin-top:10px">消息详情</div>
          <div class="container">
              今天会下特级大暴雨，请各位值班人员认真值班
          </div>
      </div>
      <div v-if="type == '5'" class="add">
         <div class="title">查看的数据</div>
             <div class="container">
                <div class="item">
                    <div>查看对象姓名:{{'五月天'}}</div>
                    <div>操作人:{{'黄石浩'}}</div>
                    <div>操作时间:{{'2020-03-11 14:22:30'}}</div>
                </div>
                <div class="item">
                    <div>查看对象姓名:{{'五月天'}}</div>
                    <div>操作人:{{'黄石浩'}}</div>
                    <div>操作时间:{{'2020-03-11 14:22:30'}}</div>
                </div>
            </div>
      </div>
      <div v-if="type == '7'" class="add">
          <div class="title">迁出的数据</div>
             <div class="container">
                <div class="item">
                    <div>迁出对象姓名:{{'五月天'}}</div>
                    <div>迁出原因:{{'五月天'}}</div>
                    <div>操作人:{{'黄石浩'}}</div>
                    <div>操作时间:{{'2020-03-11 14:22:30'}}</div>
                </div>
                <div class="item">
                    <div>迁出对象姓名:{{'五月天'}}</div>
                    <div>迁出原因:{{'五月天'}}</div>
                    <div>操作人:{{'黄石浩'}}</div>
                    <div>操作时间:{{'2020-03-11 14:22:30'}}</div>
                </div>
            </div>
      </div>
      <div v-if="type == '6'" class="add">
          <div class="title">归档的数据</div>
             <div class="container">
                <div class="item">
                    <div>归档对象姓名:{{'五月天'}}</div>
                    <div>归档原因:{{'五月天'}}</div>
                    <div>操作人:{{'黄石浩'}}</div>
                    <div>操作时间:{{'2020-03-11 14:22:30'}}</div>
                </div>
                <div class="item">
                    <div>归档对象姓名:{{'五月天'}}</div>
                    <div>归档原因:{{'五月天'}}</div>
                    <div>操作人:{{'黄石浩'}}</div>
                    <div>操作时间:{{'2020-03-11 14:22:30'}}</div>
                </div>
            </div>
      </div>
      <div v-if="type == '3'" class="add">
             <div class="title">删除的数据</div>
             <div class="container">
                <div class="item">
                    <div>删除记录对象姓名:{{'五月天'}}</div>
                    <div>操作人:{{'黄石浩'}}</div>
                    <div>操作时间:{{'2020-03-11 14:22:30'}}</div>
                </div>
                <div class="item">
                    <div>删除记录对象姓名:{{'五月天'}}</div>
                    <div>操作人:{{'黄石浩'}}</div>
                    <div>操作时间:{{'2020-03-11 14:22:30'}}</div>
                </div>
            </div>
      </div>
      <div v-if="type == 'opeartion'" class="add">
           <div class="title">查看修改的数据</div>
          <div class="container">
              <div class="item">
                   <div>查看修改记录对象姓名:{{'五月天'}}</div>
                   <div>操作人:{{'黄石浩'}}</div>
                   <div>操作时间:{{'2020-03-11 14:22:30'}}</div>
              </div>
              <div class="item">
                   <div>查看修改记录对象姓名:{{'五月天'}}</div>
                   <div>操作人:{{'黄石浩'}}</div>
                   <div>操作时间:{{'2020-03-11 14:22:30'}}</div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
    // watch:{
    //     showIndex:{
    //         fn(){
    //             this.getDetail()
    //         }
            
    //     }
    // },
    props:{
        // showIndex:{
        //     type:Number,
        //     default:()=> {
        //         return 0
        //     }
        // },
        operationId:{
            type:String,
            default:()=>{
                return ''
            }
        },
        showNumber:{
            type:Number,
            default:()=>{
                return 0
            }
        },
        type:{
            type:String,
            default:()=>{
                return ''
            }
        },
        userId:{
            type:String,
            default:()=> {
                return ''
            }
        }
    },
    data(){
        return{
            talbeColumns:[
                 {
                    title: "修改项",
                    key: "fieldName",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "修改前",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "修改后",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                }
            ],
            checkColumns:[
                 {
                    title: "查看对象姓名",
                    key: "fieldName",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "操作人",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "操作时间",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                }
            ],
            outColumns:[
                 {
                    title: "迁出对象姓名",
                    key: "fieldName",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "迁出原因",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "操作人",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "操作时间",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
            ],
            inColumns:[
                 {
                    title: "归档对象姓名",
                    key: "fieldName",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "归档原因",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "操作人",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "操作时间",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
            ],
            deleteColumns:[
                 {
                    title: "删除对象姓名",
                    key: "fieldName",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "操作人",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "操作时间",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
            ],
            checkEditColumns:[
                 {
                    title: "查看修改记录对象姓名",
                    key: "fieldName",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "操作人",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "操作时间",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
            ],
            
            tableData:[],
            loading:false
        }
    },
    methods:{
        // getList(){

        // },
        getDetail(){
            this.$get('syaa/api/syuser/pc/residentArchive/selectUpdateDetail',
            {
                operationId:this.operationId,
                userId:parent.vue.loginInfo.userinfo.custGlobalId
            }).then(res => {
                
            })
        }
    },
    watch:{
        showNumber:{
            handler(val){
                if(val){
                    this.getDetail()
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.title{
     position: relative;
     padding-left: 20px;
     margin-bottom:10px;
     &::after{
         position: absolute;
         content:'';
         top: 0;
         left: 0;
         width: 5px;
         height: 20px;
         background-color: #2db7f5;
     }
 }
.export{
 
    .container{
        .item{
            display: flex;
            margin: 10px;
            border-bottom: 1px solid #cccccc;
            padding-bottom: 10px;
            &:nth-last-child(1){
                border-bottom: none;
            }
            .left{
                width: 48%;
            }
            .right{
                margin-left: 2%;
                width: 48%;
                word-break: break-word;
            }
        }
    }
}
.edit{
    .top{
        display: flex;
        .item{
            width:33.3%
        }
    }
    .table{
        margin-top: 10px;
    }
}
.add{
    .container{
        .item{
            margin: 10px;
            border-bottom: 1px solid #cccccc;
            padding-bottom: 10px;
             &:nth-last-child(1){
                border-bottom: none;
            }
        }
    }
}
.send{
    .container{
        padding: 10px;
        width: 100%;
        height: 200px;
        border: 1px solid #cccccc;
        overflow-y: scroll;
    }
}

</style>