<template>
  <div class="container">
       <div class="top">
           <Form
            :inline="true"
            class="form"
           >
                <FormItem class="item">
                    <span slot="label">操作人:</span>
                    <Input
                        :maxlength="20"
                        v-model.trim="searchForm.name"
                        placeholder="请输入操作人"
                        style="width: 150px"
                    ></Input>
                </FormItem>
                <FormItem class="item">
                    <span slot="label">操作时间:</span>
                    <DatePicker v-model="searchTime" @on-change="changeTime" type="daterange" confirm placement="bottom-end" placeholder="请选择修改时间" style="width: 200px"></DatePicker>
                </FormItem>
                <Button
                    type="primary"
                    icon="ios-search-outline"
                    @click="search"
                    style="margin-right: 10px;margin-bottom:10px"
                    >查询</Button
                    >
                <Button type="success" @click="reset" icon="ios-refresh" style="margin-bottom:10px">重置</Button>
           </Form>
      </div>
      <div class="table">
          <Table  :columns="talbeColumns" :data="tableData"  height="680" border stripe></Table>
         <Page
                style="float: right; margin: 10px"
                @on-change="morePageFn"
                :current="page"
                @on-page-size-change="PageSizeChange"
                :page-size="pageSize"
                :total="total"
                :page-size-opts="[20, 50, 100, 200]"
                size="small"
                show-total
                show-elevator
                show-sizer
              />
      </div>
      <LiefengModal
        :value="selectModal"
        title="查看详情"
        @input="selectStatus"
        :fullscreen="false"
        height="600px"
        width="800px"
      >
        <template v-slot:contentarea>
                <Index :operationId ="operationId " :showNumber="showNumber" :type="showType" ></Index>
        </template>
        <template v-slot:toolsbar>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="selectCancel">确定</Button>
        </template>
      </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
import Index from '../record/index.vue'
export default {
    props:{
        isShow:{
            type:Number,
            default:()=>{
                return 0 
            }
        }
    },
    data(){
        return{
             talbeColumns:[
                {
                    title: "操作类型",
                    key: "staffName",
                    minWidth: 200,
                    align: "center",
                    render:(h,params)=>{
                        return h('div',{},params.row.operationType ? (params.row.operationType == 1 ?'新增居民':params.row.operationType == 2?'查看修改记录':params.row.operationType == 3 ?'删除':params.row.operationType == 4 ?'导出':params.row.operationType == 5 ?'查看':params.row.operationType == 6 ?'归档':params.row.operationType == 7 ?'迁出':'') :'')
                    }
                },
                {
                    title: "操作人",
                    key: "staffName",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "操作时间",
                    // key: "operatrionTime",
                    minWidth: 200,
                    align: "center",
                    render:(h,params)=>{
                        return h('div',{},params.row.operatrionTime ? this.$core.formatDate(
                            new Date(params.row.operatrionTime),
                            "yyyy-MM-dd hh:mm:ss"
                        ):'')
                    }
                },
                 {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    minWidth: 300,
                    render: (h, params) => {
                        return h("div", [
                        h(
                            "Button",
                            {
                            props: {
                                type: "info",
                                size: "small"
                            },
                            on: {
                                click: () => {

                                        this.selectModal = true
                                        this.operationId  = params.row.operationId 
                                        ++this.showNumber
                                        this.showType = params.row.operationType

                                    }
                                }
                            },
                            "查看详情"
                        ),
                        ]);
                    }
                    }
            ],
            tableData:[
                // {type:'export',staffName:'导出'},
                // {type:'import',staffName:'导入'},
                // {type:'edit',staffName:'修改'},
                // {type:'add',staffName:'新增居民'},
                // {type:'send',staffName:'发消息'},
                // {type:'check',staffName:'查看'},
                // {type:'out',staffName:'迁出'},
                // {type:'in',staffName:'归档'},
                // {type:'delete',staffName:'删除'},
                // {type:'opeartion',staffName:'查看修改纪录'},
            ],
            page:1,
            pageSize:20,
            total:0,

            // 搜索的内容
            searchTime:[],
            searchForm:{
                name:'',
                startTime:'',
                endTime:''
            },
            selectModal:false,
            operationId :'',
            showNumber:0,
            showType:''
        }
    },
    methods:{
        selectStatus(status){
            this.selectModal = status
        },
        selectCancel(){
            this.selectModal = false
        },
        // 搜索按钮
        search(){
            console.log(this.searchForm,this.searchTime)
            this.getList()
        },
        // 重置按钮
        reset(){
            this.searchTime = []
            this.searchForm = {
                name:'',
                startTime:'',
                endTime:''
            }
        },
         // 选择了时间的回调事件
        changeTime(val){
            if(val){
                this.searchForm.startTime = val[0]
                this.searchForm.endTime = val[1]
            }
        },
        // 分页
        morePageFn(val) {
            this.page = val
            this.getList()
        },
        PageSizeChange(val){
            this.pageSize = val
            this.page = 1
            this.getList()
        },
         // 获取分页接口
        getList(){
            this.loading = true
            this.$get('/syaa/api/syuser/pc/residentArchive/selectOperationLogPage',{
                page:this.page,
                pageSize:this.pageSize,
                staffName:this.searchForm.name,
                operatrionTimeStart:this.searchForm.startTime,
                operatrionTimeEnd:this.searchForm.endTime,
                // operationType:2
            }).then(res=>{
                this.loading = false
                if(res.code == 200){
                    this.tableData = res.dataList
                    this.total = res.maxCount

                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
    },
    watch:{
        isShow:{
            handler(val){
                if(val){
                    this.page = 1
                    this.pageSize = 20
                    this.getList()
                }
            }
        }
    },
    components:{LiefengModal,Index}

}
</script>

<style lang="less" scoped>
    .container{
        .top{
            height: 40px;
            width: 100%;
            line-height: 40px;
            display: flex;
            .form{
                margin-left: auto;
            }
        }
    }
    /deep/.ivu-form-item-content{
        display: flex;
    }
    /deep/.ivu-table-tip{
        overflow-x: hidden !important;
    }
</style>