<template>
    <LiefengContent @click.native="changeOther" :isBack="$route.query.isLineHome ? true : false" @backClick="$router.push('/linehomepage')">
        <template v-slot:title>
            民情档案
            <span @click.stop="changeEye">
                <Icon :type="hasSensitive ? 'md-eye-off' : 'md-eye'" />
            </span>
            <!-- <Button type="error" v-if="" @click="">返回</Button> -->
        </template>
        <template v-slot:toolsbarLeft>
            <LiefengTree ref="mychild" treeID="mychild" fixedContentID="contentarea" :fixedmenu="true" @handleData="getList" style="width: 200px"></LiefengTree>
        </template>
        <template v-slot:toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Input :maxlength="20" enter-button v-model.trim="searchData.name" placeholder="姓名" style="margin-right: 10px; width: 150px" @on-enter="getList" />
                </FormItem>
                <FormItem>
                    <Input :maxlength="20" enter-button width="100px" v-model.trim="searchData.phone" placeholder="手机号" style="margin-right: 10px; width: 150px" @on-enter="getList" />
                </FormItem>
                <!-- <Select style="width:100px" v-model="searchData.status" placeholder="请选择状态" clearable>
              <Option v-for="(item,index) in statusList" :key="index" :value="item.value">{{item.label}}</Option>
            </Select> -->
                <Button type="primary" style="margin-right: 10px" icon="ios-search" @click="getList">搜索</Button>
                <Button type="success" style="margin-right: 10px" icon="ios-refresh" @click="rest">重置</Button>
                <!-- <Button  @click="importExcel" type="primary" icon="ios-add-circle-outline">导入</Button> -->
                <Button
                    type="warning"
                    style="margin-right: 10px"
                    icon="ios-mail"
                    @click="
                        $router.push({
                            path: '/smsrecordindex?name=linehomeindex',
                            query: { isLineHome: $route.query.isLineHome ? $route.query.isLineHome : '', page: $route.query.page ? $route.query.page : '' },
                        })
                    "
                >
                    短信
                </Button>
                <Button type="primary" style="margin-right: 10px" icon="ios-add" @click="addForm">新增居民</Button>
                <Button
                    type="primary"
                    style="margin-right: 10px"
                    @click="
                        $router.push({
                            path: '/linehomeconfirmed',
                            query: { communityCode: treeParams.communityCode, isLineHome: $route.query.isLineHome ? $route.query.isLineHome : '', page: $route.query.page ? $route.query.page : '' },
                        })
                    "
                >
                    导入并同步
                    <span style="color: red">({{ num }})</span>
                </Button>

                <Button
                    type="primary"
                    style="margin-right: 10px"
                    @click="
                        $router.push({
                            path: '/linehomemanage?name=linehomeindex&id=',
                            query: { communityCode: treeParams.communityCode, isLineHome: $route.query.isLineHome ? $route.query.isLineHome : '', page: $route.query.page ? $route.query.page : '' },
                        })
                    "
                >
                    线上居民审核
                    <span style="color: red">({{ residentNum }})</span>
                </Button>
                <Button
                    type="primary"
                    style="margin-right: 10px"
                    @click="
                        $router.push({
                            path: '/verified?name=linehomeindex',
                            query: { id: changeId, isLineHome: $route.query.isLineHome ? $route.query.isLineHome : '', page: $route.query.page ? $route.query.page : '' },
                        })
                    "
                >
                    实名审核
                    <span style="color: red">({{ realNameNum }})</span>
                </Button>
                <!-- <Button type="warning" icon="ios-mail" @click="$router.push('/emailgroup?name=linehomeindex')">发消息</Button> -->

                <!-- <Button type="primary" @click="operationRecord">操作记录</Button> -->

                <Button @click="exportExcel" style="margin-right: 10px" type="error" icon="ios-cloud-download-outline">导出</Button>
                <!-- <Dropdown>
              <Button type="primary">
                  更多
                  <Icon type="ios-arrow-down"></Icon>
              </Button>
              <DropdownMenu slot="list" style="display: flex;flex-direction: column;">
                <Button type="primary" style='margin:5px 10px' @click="operationRecord">操作记录</Button>
                <Button type="primary" style='margin:5px 10px' @click="$router.push('/smsrecordindex?name=linehomeindex')">短信</Button>
                <Button type="primary" style="margin:5px 10px" @click="$router.push('/linehomeconfirmed')">导入并同步</Button>
              </DropdownMenu>
            </Dropdown> -->
                <!-- <Button type="primary" v-if="$route.query.isLineHome" @click="$router.push('/linehomepage')">返回</Button> -->
                <!-- <Button type="primary" icon="ios-add" @click="addForm">新增居民</Button> -->
                <!-- <Button type="primary" @click="setFormMessage = true">设置查看信息</Button>
            <Button type="primary" @click="setAddMessage">设置新增信息</Button> -->
                <Icon type="md-more" @click="selectQuery = true" style="font-size: 24px; line-height: 32px; cursor: pointer" title="更多查询条件" />
            </Form>

            <Drawer
                title="查询条件"
                v-model="selectQuery"
                width="440"
                :styles="{
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: '53px',
                    position: 'static',
                }"
            >
                <Form :model="searchData" :label-colon="true">
                    <FormItem label="姓名">
                        <Input v-model.trim="searchData.name" placeholder="请输入姓名" :maxlength="20"></Input>
                    </FormItem>
                    <FormItem label="手机号">
                        <Input :maxlength="20" v-model.trim="searchData.phone" placeholder="请输入手机号"></Input>
                    </FormItem>
                    <FormItem label="住房编码">
                        <Input :maxlength="20" v-model.trim="searchData.houseNo" placeholder="请输入住房编码" style="width: 100%" />
                    </FormItem>
                    <FormItem label="身份类型">
                        <Select v-model="searchData.userType" placeholder="请选择身份类型" clearable>
                            <Option value="4">租赁人员</Option>
                            <Option value="3">是业主且户籍在此</Option>
                            <Option value="2">是业主但户籍不在此</Option>
                            <Option value="1">仅户籍在此</Option>
                            <Option value="5">其他</Option>
                        </Select>
                    </FormItem>
                    <!-- <FormItem label="详细地址">
            <Input
              :maxlength="20"
              v-model.trim="searchData.wholeAddr"
              placeholder="请输入详细地址"
              style="width: 100%"
            />
          </FormItem> -->
                    <FormItem label="家庭联络人">
                        <Select v-model="searchData.contact" placeholder="请选择是否家庭联络人" clearable>
                            <Option value="1">是</Option>
                            <Option value="2">否</Option>
                        </Select>
                    </FormItem>
                </Form>
                <div class="demo-drawer-footer">
                    <Button style="margin-right: 8px" @click="selectQuery = false">取消</Button>
                    <Button type="primary" @click="getList">查询</Button>
                </div>
            </Drawer>

            <Drawer
                title="设置查看信息"
                v-model="setFormMessage"
                width="440"
                :styles="{
                    height: 'calc(100% - 55px)',
                    overflow: 'auto',
                    paddingBottom: '53px',
                    position: 'static',
                }"
            >
                <div class="check-div">
                    <div style="margin: 10px 0">{{ "表格字段查看:" }}</div>
                    <CheckboxGroup @on-change="changeCheck" v-model="checkSelectList" class="check-box" style="width: 100%">
                        <Checkbox v-for="(item, index) in checkList" :key="index" :label="item.id" class="check-item" style="width: 45%; margin-right: 5px; margin-bottom: 5px">
                            {{ item.name }}
                        </Checkbox>
                    </CheckboxGroup>
                </div>
                <div class="demo-drawer-footer">
                    <Button type="primary" @click="setFormMessage = false">确定</Button>
                </div>
            </Drawer>

            <!--         驳回弹窗 -->
            <LiefengIndexModal ref="modelValue1" :value="jectionToggle" :fullscreen="false" :toggle-fn2="down" :toggle="true" @unteData="unteData"></LiefengIndexModal>
        </template>
        <template v-slot:contentArea>
            <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="pageNum"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="getList"
                ref="baseList"
            ></LiefengTable>
            <!-- 发消息 -->
            <LiefengModal :value="msgModal" title="发消息" width="800px" height="calc(100vh - 200px)" @input="sentMsgModal">
                <template v-slot:contentarea>
                    <Form :model="msgform" :label-width="80" ref="msgform" :rules="ruleValidate">
                        <formItem>
                            <Button type="primary" @click="selectUser">选择接收人</Button>
                        </formItem>
                        <FormItem label="接收人" prop="recIds">
                            <Input disabled v-model.trim="selectName" type="textarea" :rows="2" placeholder="请选择接收人"></Input>
                        </FormItem>
                        <!-- <FormItem label="标题" prop="title">
              <Input
                v-model.trim="msgform.title"
                placeholder="请输入标题"
                maxlength="30"
                show-word-limit
              ></Input>
            </FormItem> -->
                        <FormItem label="内容" prop="content">
                            <Input v-model.trim="msgform.content" type="textarea" :maxlength="800" show-word-limit :rows="8" placeholder="请输入内容"></Input>
                        </FormItem>
                        <FormItem label="短信方式">
                            <RadioGroup v-model="msgform.sendChannel">
                                <Radio label="1">是</Radio>
                                <Radio label="2">否</Radio>
                            </RadioGroup>
                        </FormItem>
                        <div class="title" style="padding-left: 100px; font-size: 16px" v-if="msgform.sendChannel == 1">
                            当前将发送短信共:
                            <span style="color: red">{{ resTotal || 0 }}</span>
                            条，预计费用:
                            <span style="color: red">{{ resFee || 0 }} 元</span>
                        </div>
                        <div class="title" style="padding-left: 100px" v-if="msgform.sendChannel == 1">
                            <div style="margin: 10px 0">计费说明:</div>
                            <div style="margin: 10px 0">1、短信费用为0.07元/条；</div>
                            <div style="margin: 10px 0">2、每70个字算1条。</div>
                        </div>
                    </Form>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="sendRest" style="margin-right: 10px">清空</Button>
                    <Button type="primary" @click="sendOK('msgform')">确定</Button>
                </template>
            </LiefengModal>
            <!--选择接收人-->
            <LiefengModal :value="selectModal" title="选择接收人" width="1300px" height="calc(100vh - 200px)" @input="selectStatus" :fullscreen="true">
                <template v-slot:contentarea>
                    <selectUser ref="selchildren" @handleSelect="selectData" @communityCode="communityCodeFn"></selectUser>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin: 0 5px 0 13px" type="primary" @click="saveUser">保存</Button>
                </template>
            </LiefengModal>
            <!--居民操作纪录-->
            <LiefengModal :value="operationModal" title="居民档案操作纪录" @input="operationStatus" :fullscreen="true">
                <template v-slot:contentarea>
                    <Operation :isShow="isShow"></Operation>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin: 0 5px 0 13px" type="primary" @click="operationStatus(false)">确定</Button>
                </template>
            </LiefengModal>

            <!-- 导出模板列表框 -->
            <LiefengModal :value="templateStatus" title="导出模板" width="1250px" height="calc(100vh - 170px)" @input="changeTemplateStatus">
                <template v-slot:contentarea>
                    <div class="template-list">
                        <div class="template-box template-add" @click="addTemplate">
                            <Icon type="md-add" size="60" color="#2D8CF0" />
                            <p>新建导出模板</p>
                        </div>

                        <div class="template-box" v-for="(item, index) in templateList" :key="index">
                            <Button icon="md-close" style="float: right" @click="deleteTemplate(item)"></Button>
                            <p :title="item.templateName">{{ item.templateName }}</p>
                            <p :title="item.fieldNameList.join()">{{ item.fieldNameList.join() }}</p>
                            <Button style="position: absolute; top: 67%; left: 50%; transform: translate(-50%, 0)" type="primary" @click="saveOperation(item.templateId, item.templateName)">
                                按照此模板导出
                            </Button>
                            <p>模板创建时间：{{ $core.formatDate(new Date(item.gmtCreate), "yyyy-MM-dd") }}</p>
                        </div>
                    </div>
                </template>
            </LiefengModal>
            <!-- 添加模板列表框 -->
            <LiefengModal :value="addTemplateStatus" title="新建导出模板" width="1250px" height="calc(100vh - 170px)" @input="changeAddTemplateStatus">
                <template v-slot:contentarea>
                    导出报表名称&nbsp;&nbsp;
                    <Input v-model.trim="templateName" placeholder="限30字" clearable :maxlength="30" style="width: 400px" />
                    <ul class="field-list">
                        <li style="line-height: 30px">请选择要导出的字段</li>
                        <li v-for="(item, index) in templateField" :key="index">
                            <div class="lable">
                                <span style="color: red" v-if="item.formId == 1">*</span>
                                {{ item.formName }}
                            </div>
                            <div class="field">
                                <Checkbox v-model="e.status" v-for="(e, i) in item.authFieldList" :key="i">{{ e.fieldName }}</Checkbox>
                            </div>
                        </li>
                    </ul>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin: 0 5px 0 13px" type="primary" @click="saveAndExport">保存模板并导出数据</Button>
                    <Button style="margin: 0 5px 0 13px" type="info" @click="saveTemplate">仅保存报表模板</Button>
                    <Button style="margin: 0 5px 0 13px" @click="cancelAdd">取消</Button>
                </template>
            </LiefengModal>

            <!-- 导入 -->
            <LiefengModal title="导入" width="40%" height="240px" :value="excelStatus" @input="excelModalData" class="export-modal">
                <template v-slot:contentarea>
                    <!-- <Form :model="searchForm" :label-width="100">
            <FormItem>
               <span slot="label" class="validate">请选择社区:</span>
               <Tooltip placement="right-start"  style="width:100%">
                <div slot="content" style="width: 200px;white-space: normal">
                  由于社区太多，请输入关键字进行刷选
                </div>
                <Select
                  v-model="nodeId"
                  filterable
                  @on-change="getTree"
                  placeholder="点击列表/输入搜索"
                  :remote-method="loadingCommunityFn"
                  :loading="loadingCommunity"
                >
                <Option v-for="(item,index) in nodeList" :value="item.value" :key="index">{{ item.label }}</Option>
                </Select>
            </Tooltip>
            </FormItem>
          </Form> -->

                    <LiefengUploadExcel
                        style="margin: 20px 0"
                        :showDownModal="{ isShow: true, downLink: './excelfiles/popularSentiment.xlsx', downName: '民情档案导入模板' }"
                        :headers="headers"
                        :action="exportAction"
                        :uploadData="uploadData"
                        :isShow="showExcel"
                        @success="excelSuccess"
                    ></LiefengUploadExcel>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
//@route("/linehomeindex")
import LiefengUploadExcel from "./childrens/LiefengUploadExcel.vue"
import LiefengContent from "@/components/LiefengContent3"
import LiefengTree from "./childrens/LiefengTree.vue"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import selectUser from "@/views/linehome/childrens/selectUser"
import Operation from "@/views/linehome/childrens/operation"
import LiefengIndexModal from "@/views/linehome/LiefengIndexModal"

export default {
    watch: {
        changeNum() {
            this.getSelect(this.treeParams.communityCode)
        },

        selectQuery(newV) {
            if (!newV) {
                this.searchData.name = ""
                this.searchData.phone = ""
                this.searchData.houseNo = ""
                this.searchData.userType = ""
                this.searchData.wholeAddr = ""
                this.searchData.contact = ""
            }
        },
    },
    data() {
        return {
            // 脱敏
            hasSensitive:true,
            num: 0,

            //与户主关系
            relationshipList: [],

            changeNum: 0,
            changeId: "",

            // 导出模板列表框
            templateStatus: false,

            // 新建导出模板框
            addTemplateStatus: false,

            // 模板列表
            templateList: [],

            //模板字段
            templateField: [],
            templateFieldList: [],

            // 新建报表名称
            templateName: "",

            realNameNum: 0,
            residentNum: 0,

            // 导入模板属性
            //异步加载社区
            loadingCommunity: false,
            excelStatus: false,
            nodeId: "",
            nodeList: [],
            headers: { openId: window.sessionStorage.getItem("openId"), Authorization: window.sessionStorage.getItem("accessToken") },
            exportAction: "",
            //上传附带而外参数
            uploadData: {
                // file:,
                orgCode: "",
                impStaff: "",
                communityCode: "",
            },
            showExcel: 0,

            isBlock: false,
            //保存按钮传过来的data
            updataPassObj: {},
            //end
            //驳回弹窗
            jectionToggle: false,

            // 查询更多
            selectQuery: false,
            //表格加载loading
            loading: false,
            communityCode: "",
            nocommunityCode: "",
            total: 0,
            treeData: [],
            msgModal: false,
            selectModal: false,
            msgform: {
                sendChannel: "1",
                businessCode: "0000", //业务代码 0000:发送系统信息
                businessType: 5, // 业务类型5:发送系统信息
                oemCode: parent.vue.oemInfo.oemCode,
                recIds: "",
                title: "",
                content: "",
                sendId: parent.vue.loginInfo.userinfo.custGlobalId,
            },
            talbeColumns: [
                // // {
                // //   type: "selection",
                // //   width: 60,
                // //   align: "center"
                // // },
                // {
                //   title: "住房编号",
                //   key: "houseCode",
                //   minWidth: 100,
                //   align: "center"
                // },
                // {
                //   title: "姓名",
                //   key: "name",
                //   width: 100,
                //   align: "center"
                // },
                // {
                //   title: "性别",
                //   width: 80,
                //   align: "center",
                //    render: (h, params) => {
                //     return h("div", {},params.row.sex == '0' ?'未知':params.row.sex == '1' ?'男':params.row.sex == '2'?'女':'')
                //   }
                // },
                // {
                //   title: "联系电话",
                //   key: "phone",
                //   width: 130,
                //   align: "center"
                // },
                // {
                //   title: "身份类型",
                //   width: 120,
                //   align: "center",
                //   render: (h, params) => {
                //     return h("div", {},params.row.userType == '1' ?'仅户籍在此':params.row.userType == '2' ?'是业主但户籍不在此':params.row.userType == '3'?'是业主且户籍在此' : params.row.userType == '4' ?'租赁人员':params.row.userType == '5' ?'其他':'')
                //   }
                // },
                // {
                //   title: "入驻时间",
                //   key: "gmtCreate",
                //   width: 180,
                //   align: "center"
                // },
                // {
                //   title: "所在详细地址",
                //   width: 300,
                //   align: "center",
                //   render: (h, params) => {
                //     return h("div", [
                //       h(
                //         "span",
                //         {
                //           style: {
                //             // display: "inline-block",
                //             // width: "100%",
                //             // overflow: "hidden",
                //             // textOverflow: "ellipsis",
                //             // whiteSpace: "nowrap"
                //           },
                //           domProps: {
                //             title: params.row.regionGovernmentVo.wholeAddr  || ''
                //           },
                //           on: {
                //             click: e => {
                //               e.stopPropagation();
                //             }
                //           }
                //         },
                //         params.row.regionGovernmentVo.wholeAddr  || ''
                //       )
                //     ]);
                //   }
                // },
                // {
                //   title: "是否家庭联络人",
                //   width: 140,
                //   align: "center",
                //   key:'contact'
                //   // render: (h, params) => {
                //   //   return h("div", {},params.row.contact == '1' ?'是':'否')
                //   // }
                // },
                // {
                //   title: "状态",
                //   width: 100,
                //   align: "center",
                //   key:'status',
                //   renderHeader: (h,params)=>{
                //                 return h('div',[
                //                     h('strong','状态'),
                //                     h('Icon',{
                //                         props:{
                //                             type: 'md-apps'
                //                         },
                //                         style: {
                //                             marginLeft: '5px'
                //                         },
                //                         on:{
                //                           click:e=>{
                //                             e.stopPropagation();
                //                             this.isBlock = !this.isBlock;
                //                           }
                //                         }
                //                     }),
                //                     h('div',{
                //                       style:{
                //                         marginTop:'15px',
                //                         marginLeft:'-80px',
                //                         // transform:'translateY(150)',
                //                         // transform:'translateX(300)',
                //                         width:'250px',
                //                         display:this.isBlock?'block':'none',
                //                         position:'fixed',
                //                         background:'white',
                //                         border:'1px solid #ccc',
                //                         textAlign:'left',
                //                         padding:'10px',
                //                         transition:'all 1s',
                //                         cursor:'pointer',
                //                         userSelect:'none',
                //                         zIndex:9999
                //                       },
                //                       on:{
                //                           click:e=>{
                //                             e.stopPropagation();
                //                           }
                //                         }
                //                     },[
                //                       <div>
                //                       <p style="text-align:center;margin-bottom:10px">网上家园居民信息状态说明</p>
                //                       <p>已通过：居民入驻网上家园成功</p>
                //                       <p>待审核：居民选择了详情地址并完善了身份信息，如：姓名、昵称、身份类型（与住址的关系），等待居委审核该信息的真实情况</p>
                //                       <p>已驳回：居委驳回了此条信息，等待居民重新修改提交</p>
                //                       <p>待确认：居民只选择了详情住址，未完善身份信息</p>
                //                       </div>
                //                       ])
                //                 ])
                //             }
                // },
                // {
                //   title: "操作",
                //   slot: "action",
                //   align: "center",
                //   minWidth: 300,
                //   fixed:"right",
                //   render: (h, params) => {
                //     return h("div", [
                //       h(
                //         "Button",
                //         {
                //           props: {
                //             type: "info",
                //             size: "small"
                //           },
                //           on: {
                //             click: () => {
                //               this.$router.push(`/linehomeaddform?isEdit=true&userId=${params.row.userId}&custGlobalId=${params.row.custGlobalId}&familyId=${params.row.familyId}&familyMemberId=${params.row.familyMemberId}&orgCode=${params.row.orgCode}`);
                //             }
                //           }
                //         },
                //         "修改"
                //       ),
                //       h(
                //         "Button",
                //         {
                //            style: {
                //             marginLeft: "10px"
                //           },
                //           props: {
                //             type: "info",
                //             size: "small"
                //           },
                //           on: {
                //             click: () => {
                //               this.remove(params.row);
                //             }
                //           }
                //         },
                //         "删除"
                //       ),
                //       h(
                //         "Button",
                //         {
                //           style: {
                //             display: params.row.status == "待审核" ? "inline-block" : "none",
                //             marginLeft: "10px"
                //           },
                //           props: {
                //             type: "info",
                //             size: "small"
                //           },
                //           on: {
                //             click: () => {
                //               this.rePass(params.row);
                //             }
                //           }
                //         },
                //         "通过"
                //       ),
                //       h(
                //         "Button",
                //         {
                //           props: {
                //             type: "info",
                //             size: "small"
                //           },
                //           style: {
                //             display: params.row.status == "待审核" ? "inline-block" : "none",
                //             marginLeft: "10px"
                //           },
                //           on: {
                //             click: () => {
                //               this.noPass(params.row);
                //             }
                //           }
                //         },
                //         "驳回"
                //       )
                //     ]);
                //   }
                // }
            ],
            tableData: [],
            selection: [],
            showDo: true,
            hideEdit: true,
            hidenBread: true,
            hideDel: false,
            hideSearch: false,
            pageNum: 1,
            pageSize: 20,
            orgCode: "",
            searchData: {
                name: "",
                phone: "",
                pageSize: 20,
                pageNum: 1,
                houseNo: "",
                userType: "",
                wholeAddr: "",
                contact: "",
            },
            selectId: [],
            selectName: "",
            ruleValidate: {
                recIds: [
                    {
                        required: true,
                        message: "接收人不能为空",
                        trigger: "blur",
                    },
                ],
                title: [
                    {
                        required: true,
                        message: "标题不能为空",
                        trigger: "blur",
                    },
                ],
                content: [
                    {
                        required: true,
                        message: "内容不能为空",
                        trigger: "blur",
                    },
                ],
            },
            treeParams: {
                status: 2, //必填
                roadCode: "",
                projectCode: "",
                communityCode: "", //必填
                pageSize: 20,
                page: 1,
                name: "",
                phone: "",
                oemCode: "", //必填  缓存拿
            },
            pagess: 1,
            // statusList: [
            //   {label:'全部',value:''},
            //   { label: "待审核", value: "1" },
            //   { label: "已通过", value: "2" },
            //   { label: "已驳回", value: "3" },
            //   { label: "待确认", value: "4" }
            // ],
            reset: false,
            importRow: {},
            operationModal: false,
            isShow: 0,
            resTotal: 0,
            resFee: 0,
            idsList: "",

            // 选择的checkbox参数
            checkSelectList: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],

            // 存放checkbox数组的地方
            checkList: [
                { id: "1", name: "房号", checked: true },
                { id: "2", name: "姓名", checked: true },
                { id: "3", name: "性别", checked: true },
                { id: "4", name: "联系电话", checked: true },
                { id: "5", name: "与户主关系", checked: true },
                { id: "6", name: "年龄", checked: true },
                { id: "7", name: "类别", checked: true },
                { id: "8", name: "所在详细地址", checked: true },
                { id: "9", name: "是否家庭户代表", checked: true },
                { id: "10", name: "是否已入驻网上家园", checked: true },
                { id: "11", name: "数据状态", checked: false },
                { id: "12", name: "档案状态", checked: true },

                { id: "13", name: "疫苗接种情况", checked: false },
                { id: "14", name: "是否户主", checked: false },
                { id: "15", name: "户籍身份类型", checked: false },
                { id: "16", name: "状态", checked: false },
                { id: "17", name: "籍贯", checked: false },
                { id: "18", name: "婚姻状况", checked: false },
                { id: "19", name: "政治面貌", checked: false },
                { id: "20", name: "文化程度", checked: false },
                { id: "21", name: "有无犯罪记录", checked: false },
                { id: "22", name: "是否空挂户", checked: false },
                { id: "23", name: "经济状况", checked: false },
                { id: "24", name: "是否有精神障碍", checked: false },
                { id: "25", name: "是否为退休人员", checked: false },
                { id: "26", name: "党内职务", checked: false },
                { id: "27", name: "是否加入党员服务队", checked: false },
                { id: "28", name: "志愿者信息-编号", checked: false },
                { id: "29", name: "志愿者信息-分类", checked: false },
                { id: "30", name: "居民小组信息-组别", checked: false },
                { id: "31", name: "居民小组信息-组内职务", checked: false },
                { id: "32", name: "健康需求评估-自理能力", checked: false },
                { id: "33", name: "残疾类型", checked: false },
                { id: "34", name: "低保救助时限", checked: false },
                { id: "35", name: "低保救助身份", checked: false },
                { id: "36", name: "低保救助医疗保险", checked: false },
                { id: "37", name: "低保救助养老保险", checked: false },
                { id: "38", name: "孤寡情况", checked: false },
                { id: "39", name: "平安通/平安钟", checked: false },
                { id: "40", name: "残疾人社保状况", checked: false },
                { id: "41", name: "残疾人医保状况", checked: false },
                { id: "42", name: "军职类别", checked: false },
            ],

            // 存储sessionStorage字段名称
            sessionName: "",

            // 设置查看字段弹窗状态
            setFormMessage: false,

            // 跳转到建设总览
            overView: {
                orgName: "",
                orgCode: "",
            },
        }
    },
    components: {
        LiefengContent,
        LiefengTree,
        LiefengTable,
        selectUser,
        LiefengModal,
        LiefengIndexModal,
        Operation,
        LiefengUploadExcel,
    },
    created() {
        this.getDictionaries()
        this.sessionName = parent.vue.loginInfo.userinfo.custGlobalId + parent.vue.loginInfo.userinfo.orgCode
        this.checkSessionList()
        this.getTemplate()
        this.getField()
    },
    filters: {
        filterTime(val) {
            let time = new Date(val)
            let y = time.getFullYear() + 1
            let m = time.getMonth()
            let d = time.getDate()
            return `${y}年${m}月${d}日`
        },
    },
    // filters: {
    //   filtersTime(val, that) {
    //     if (val) {
    //       return that.$core.formatDate(new Date(val), "yyyy-MM-dd hh:mm:ss");
    //     } else {
    //       return "未知";
    //     }
    //   },
    // },
    methods: {
        // 获取与户主关系字典
        getDictionaries() {
            this.$get("/voteapi/api/common/sys/findDictByDictType", {
                dictType: "FAMILY_RELATIONSHIP",
            }).then(res => {
                this.relationshipList = res.dataList
            })
        },
        getUserAuthCount(id) {
            this.$get("/old/api/pc/userAuth/getUserAuthCount", {
                projectId: id,
                orgCode: parent.vue.loginInfo.orgCode,
                staffId: parent.vue.loginInfo.userinfo.id,
                status: 0,
            }).then(res => {
                if (res.code == 200) {
                    this.realNameNum = res.data
                }
            })
        },

        // 导入模板方法
        // 导入
        importExcel() {
            this.uploadData.communityCode = ""
            this.uploadData.orgCode = parent.vue.loginInfo.userinfo.orgCode
            this.uploadData.impStaff = parent.vue.loginInfo.userinfo.nickName
            this.excelStatus = true
            this.showExcel = this.showExcel + 1
            this.exportAction = window.vue.getProxy()["/syaa"].target + "/upload/syuser/pc/residentArchive/importImpUser"
            // this.exportAction =  "syaa/api/syuser/pc/company/importImpUser";
            // console.log('true')
        },
        excelModalData(status) {
            this.excelStatus = status
        },
        getSelect(code) {
            this.$get("/old/api/pc/project/selectStaffManagerCommunity", {
                staffId: parent.vue.loginInfo.userinfo.id,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
            })
                .then(res => {
                    if (res.code === "200" && res.dataList && res.dataList.length > 0) {
                        res.dataList.forEach(e => {
                            if (e.code === code) {
                                this.changeId = e.id
                                this.getUserAuthCount(e.id)
                            }
                        })
                        // this.getTree(this.communityCode);   //第一次进来默认获取第一个社区数据
                        this.nodeList = res.dataList.map(item => {
                            return {
                                value: item.code,
                                label: item.fullName,
                                projectCode: item.projectCode,
                            }
                        })
                        this.allNodeList = JSON.parse(JSON.stringify(this.nodeList))
                        if (this.nodeList.length > 50) {
                            this.nodeList.length = 50
                        }
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        //异步加载社区
        loadingCommunityFn(query) {
            if (query !== "" && !Number(query)) {
                this.loadingCommunity = true
                this.nodeList = this.allNodeList.filter(item => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1)
                if (this.nodeList.length > 50) this.nodeList.length = 50
                this.loadingCommunity = false
            } else if (Number(query)) {
                return
            } else {
                this.nodeList = []
            }
        },
        getTree(val) {
            this.nodeId = val
            this.uploadData.communityCode = this.nodeId
        },
        //导入成功的回调
        excelSuccess() {
            this.excelStatus = false
        },

        changeTemplateStatus(status) {
            this.templateStatus = status
        },

        // 点击了设置新增按钮
        setAddMessage() {
            this.$router.push("/linehomesetform")
        },
        // 进来先查询是否缓存有数据，没有就拿默认的
        checkSessionList() {
            let data = localStorage.getItem(this.sessionName)
            if (data && data.length != "") {
                this.checkSelectList = data.split(",")
            } else {
                this.checkSelectList = []
                this.checkList.map(item => {
                    if (item.checked == true) {
                        this.checkSelectList.push(item.id)
                    }
                })
            }
            this.talbeColumns = this.getTableColumns()
        },
        // 获取表头信息
        getTableColumns() {
            let tableColumnsList = {
                0: {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    width: 120,
                    fixed: "right",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "Dropdown",
                                {
                                    props: {
                                        transfer: true,
                                    },
                                },
                                [
                                    h(
                                        "Button",
                                        {
                                            props: {
                                                type: "info",
                                                size: "small",
                                                icon: "ios-arrow-down",
                                            },
                                        },
                                        "编辑"
                                    ),
                                    h(
                                        "DropdownMenu",
                                        {
                                            slot: "list",
                                        },
                                        [
                                            h(
                                                "DropdownItem",
                                                {
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                    nativeOn: {
                                                        click: () => {
                                                            this.alterDetails(params.row.userId, params.row.custGlobalId, params.row.familyId, params.row.familyMemberId, params.row.orgCode)
                                                            // this.$router.push(`/linehomeaddform?isEdit=true&userId=${params.row.userId}&custGlobalId=${params.row.custGlobalId}&familyId=${params.row.familyId}&familyMemberId=${params.row.familyMemberId}&orgCode=${params.row.orgCode}`);
                                                        },
                                                    },
                                                },
                                                "修改"
                                            ),
                                            // h(
                                            //   "DropdownItem",
                                            //   {
                                            //     style: {
                                            //       textAlign: "center"
                                            //     },
                                            //     nativeOn: {
                                            //       click: () => {
                                            //         this.$Message.warning({
                                            //           background: true,
                                            //           content: "该功能尚未开发",
                                            //         });
                                            //       }
                                            //     }
                                            //   },
                                            //   "查看修改记录"
                                            // ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    style: {
                                                        textAlign: "center !important",
                                                        display: params.row.archiveStatus == "4" || params.row.archiveStatus == "3" ? "none" : "block",
                                                    },
                                                    nativeOn: {
                                                        click: () => {
                                                            this.$Modal.confirm({
                                                                title: "温馨提示",
                                                                content: "是否迁出改数据",
                                                                onOk: () => {
                                                                    this.$post(
                                                                        "/syaa/api/syuser/pc/residentArchive/updateArchiveStatus",
                                                                        {
                                                                            status: 4,
                                                                            userId: params.row.userId,
                                                                        },
                                                                        { "Content-Type": "application/json" }
                                                                    ).then(res => {
                                                                        if (res.code == 200) {
                                                                            this.$Message.success({
                                                                                background: true,
                                                                                content: "迁出成功",
                                                                            })
                                                                            let data = {
                                                                                pageSize: this.pageSize,
                                                                                pageNum: this.pageNum,
                                                                            }
                                                                            this.getList(data)
                                                                        }
                                                                    })
                                                                },
                                                            })
                                                        },
                                                    },
                                                },
                                                "迁出"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    style: {
                                                        textAlign: "center",
                                                        display: params.row.archiveStatus == "3" ? "none" : "block",
                                                    },
                                                    nativeOn: {
                                                        click: () => {
                                                            this.$Modal.confirm({
                                                                title: "温馨提示",
                                                                content: "是否归档该数据",
                                                                onOk: () => {
                                                                    this.$post(
                                                                        "/syaa/api/syuser/pc/residentArchive/updateArchiveStatus",
                                                                        {
                                                                            status: 3,
                                                                            userId: params.row.userId,
                                                                        },
                                                                        { "Content-Type": "application/json" }
                                                                    ).then(res => {
                                                                        if (res.code == 200) {
                                                                            this.$Message.success({
                                                                                background: true,
                                                                                content: "归档成功",
                                                                            })
                                                                            let data = {
                                                                                pageSize: this.pageSize,
                                                                                pageNum: this.pageNum,
                                                                            }
                                                                            this.getList(data)
                                                                        }
                                                                    })
                                                                },
                                                            })
                                                        },
                                                    },
                                                },
                                                "归档"
                                            ),
                                            h(
                                                "DropdownItem",
                                                {
                                                    style: {
                                                        textAlign: "center",
                                                    },
                                                    nativeOn: {
                                                        click: () => {
                                                            this.remove(params.row)
                                                        },
                                                    },
                                                },
                                                "删除"
                                            ),
                                        ]
                                    ),
                                ]
                            ),
                            // h(
                            //   "Button",
                            //   {
                            //     style: {
                            //       display: params.row.status == "待审核" ? "inline-block" : "none",
                            //       marginLeft: "10px"
                            //     },
                            //     props: {
                            //       type: "info",
                            //       size: "small"
                            //     },
                            //     on: {
                            //       click: () => {
                            //         this.rePass(params.row);
                            //       }
                            //     }
                            //   },
                            //   "通过"
                            // ),
                            // h(
                            //   "Button",
                            //   {
                            //     props: {
                            //       type: "info",
                            //       size: "small"
                            //     },
                            //     style: {
                            //       display: params.row.status == "待审核" ? "inline-block" : "none",
                            //       marginLeft: "10px"
                            //     },
                            //     on: {
                            //       click: () => {
                            //         this.noPass(params.row);
                            //       }
                            //     }
                            //   },
                            //   "驳回"
                            // )
                        ])
                    },
                },
                1: {
                    title: "房号",
                    width: 100,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.regionGovernmentVo.houseName)
                    },
                },
                2: {
                    title: "姓名",
                    key: "name",
                    width: 100,
                    align: "center",
                },
                3: {
                    title: "性别",
                    width: 80,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.sex == "0" ? "未知" : params.row.sex == "1" ? "男" : params.row.sex == "2" ? "女" : "")
                    },
                },
                4: {
                    title: "手机号码1",
                    key: "phone",
                    width: 130,
                    align: "center",
                },
                // '5':{
                //   title: "身份类型",
                //   width: 120,
                //   align: "center",
                //   render: (h, params) => {
                //     return h("div", {},params.row.userType == '1' ?'仅户籍在此':params.row.userType == '2' ?'是业主但户籍不在此':params.row.userType == '3'?'是业主且户籍在此' : params.row.userType == '4' ?'租赁人员':params.row.userType == '5' ?'其他':'')
                //   }
                // },
                5: {
                    title: "与户主关系",
                    width: 120,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            params.row.relationship
                                ? this.relationshipList.map(item => {
                                      if (item.dictKey == params.row.relationship) {
                                          return item.dictValue
                                      }
                                  })
                                : "本人"
                        )
                    },
                },
                6: {
                    title: "年龄",
                    key: "age",
                    width: 80,
                    align: "center",
                },
                7: {
                    title: "类别",
                    key: "category",
                    width: 250,
                    align: "center",
                },
                // '6':{
                //   title: "入驻时间",
                //   key: "gmtCreate",
                //   width: 180,
                //   align: "center"
                // },
                8: {
                    title: "所在详细地址",
                    minWidth: 300,
                    align: "center",
                    render: (h, params) => {
                        return h("div", [
                            h(
                                "span",
                                {
                                    domProps: {
                                        title: params.row.regionGovernmentVo.regionWholeAddr || "",
                                    },
                                    on: {
                                        click: e => {
                                            e.stopPropagation()
                                        },
                                    },
                                },
                                params.row.regionGovernmentVo.regionWholeAddr || ""
                            ),
                        ])
                    },
                },
                9: {
                    title: "是否家庭户代表",
                    width: 140,
                    align: "center",
                    key: "contact",
                    render: (h, params) => {
                        return h("div", {}, params.row.contact == "1" ? "是" : params.row.contact == "2" ? "否" : "")
                    },
                },
                10: {
                    title: "是否已入驻网上家园",
                    key: "settleOnlineHome",
                    width: 180,
                    align: "center",
                    render: (h, params) => {
                        return h("div", {}, params.row.settleOnlineHome == "1" ? "是" : params.row.settleOnlineHome == "2" ? "否" : "")
                    },
                },
                11: {
                    title: "数据状态",
                    key: "",
                    minWidth: 140,
                    align: "center",
                },
                12: {
                    title: "档案状态",
                    width: 140,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            params.row.archiveStatus == "1"
                                ? "正常"
                                : params.row.archiveStatus == "2"
                                ? "已删除"
                                : params.row.archiveStatus == "3"
                                ? "已归档"
                                : params.row.archiveStatus == "4"
                                ? "已迁出"
                                : ""
                        )
                    },
                },
                13: {
                    title: "疫苗接种情况",
                    width: 100,
                    key: "",
                    align: "center",
                },
                14: {
                    title: "是否户主",
                    width: 80,
                    key: "",
                    align: "center",
                },
                15: {
                    title: "户籍身份类型",
                    width: 120,
                    align: "center",
                    render: (h, params) => {
                        return h(
                            "div",
                            {},
                            params.row.userType == "1"
                                ? "仅户籍在此"
                                : params.row.userType == "2"
                                ? "是业主但户籍不在此"
                                : params.row.userType == "3"
                                ? "是业主且户籍在此"
                                : params.row.userType == "4"
                                ? "租赁人员"
                                : params.row.userType == "5"
                                ? "其他"
                                : ""
                        )
                    },
                },
                16: {
                    title: "状态",
                    width: 100,
                    align: "center",
                    key: "status",
                    renderHeader: (h, params) => {
                        return h("div", [
                            h("strong", "状态"),
                            h("Icon", {
                                props: {
                                    type: "md-apps",
                                },
                                style: {
                                    marginLeft: "5px",
                                },
                                on: {
                                    click: e => {
                                        e.stopPropagation()
                                        this.isBlock = !this.isBlock
                                    },
                                },
                            }),
                            h(
                                "div",
                                {
                                    style: {
                                        marginTop: "15px",
                                        marginLeft: "-80px",
                                        // transform:'translateY(150)',
                                        // transform:'translateX(300)',
                                        width: "250px",
                                        display: this.isBlock ? "block" : "none",
                                        position: "fixed",
                                        background: "white",
                                        border: "1px solid #ccc",
                                        textAlign: "left",
                                        padding: "10px",
                                        transition: "all 1s",
                                        cursor: "pointer",
                                        userSelect: "none",
                                        zIndex: 9999,
                                    },
                                    on: {
                                        click: e => {
                                            e.stopPropagation()
                                        },
                                    },
                                },
                                [
                                    <div>
                                        <p style="text-align:center;margin-bottom:10px">网上家园居民信息状态说明</p>
                                        <p>已通过：居民入驻网上家园成功</p>
                                        <p>待审核：居民选择了详情地址并完善了身份信息，如：姓名、昵称、身份类型（与住址的关系），等待居委审核该信息的真实情况</p>
                                        <p>已驳回：居委驳回了此条信息，等待居民重新修改提交</p>
                                        <p>待确认：居民只选择了详情住址，未完善身份信息</p>
                                    </div>,
                                ]
                            ),
                        ])
                    },
                },
                17: {
                    title: "籍贯",
                    key: "",
                    width: 100,
                    align: "center",
                },
                18: {
                    title: "婚姻状况",
                    key: "",
                    width: 100,
                    align: "center",
                },
                19: {
                    title: "政治面貌",
                    key: "",
                    width: 100,
                    align: "center",
                },
                20: {
                    title: "文化程度",
                    key: "",
                    width: 100,
                    align: "center",
                },
                21: {
                    title: "有无犯罪记录",
                    key: "",
                    width: 100,
                    align: "center",
                },
                22: {
                    title: "是否空挂户",
                    key: "",
                    width: 100,
                    align: "center",
                },
                23: {
                    title: "经济状况",
                    key: "",
                    width: 100,
                    align: "center",
                },
                24: {
                    title: "是否有精神障碍",
                    key: "",
                    width: 100,
                    align: "center",
                },
                25: {
                    title: "是否为退休人员",
                    key: "",
                    width: 100,
                    align: "center",
                },
                26: {
                    title: "党内职务",
                    key: "",
                    width: 100,
                    align: "center",
                },
                27: {
                    title: "是否加入党员服务队",
                    key: "",
                    width: 100,
                    align: "center",
                },
                28: {
                    title: "志愿者信息-编号",
                    key: "",
                    width: 100,
                    align: "center",
                },
                29: {
                    title: "志愿者信息-分类",
                    key: "",
                    width: 100,
                    align: "center",
                },
                30: {
                    title: "居民小组信息-组别",
                    key: "",
                    width: 100,
                    align: "center",
                },
                31: {
                    title: "居民小组信息-组内职务",
                    key: "",
                    width: 100,
                    align: "center",
                },
                32: {
                    title: "健康需求评估-自理能力",
                    key: "",
                    width: 100,
                    align: "center",
                },
                33: {
                    title: "残疾类型",
                    key: "",
                    width: 100,
                    align: "center",
                },
                34: {
                    title: "低保救助时限",
                    key: "",
                    width: 100,
                    align: "center",
                },
                35: {
                    title: "低保救助身份",
                    key: "",
                    width: 100,
                    align: "center",
                },
                36: {
                    title: "低保救助医疗保险",
                    key: "",
                    width: 100,
                    align: "center",
                },
                37: {
                    title: "低保救助养老保险",
                    key: "",
                    width: 100,
                    align: "center",
                },
                38: {
                    title: "孤寡情况",
                    key: "",
                    width: 100,
                    align: "center",
                },
                39: {
                    title: "平安通/平安钟",
                    key: "",
                    width: 100,
                    align: "center",
                },
                40: {
                    title: "残疾人社保状况",
                    key: "",
                    width: 100,
                    align: "center",
                },
                41: {
                    title: "残疾人医保状况",
                    key: "",
                    width: 100,
                    align: "center",
                },
                42: {
                    title: "军职类别",
                    key: "",
                    width: 100,
                    align: "center",
                },
            }

            let data = [tableColumnsList["0"]]

            if (this.checkSelectList && this.checkSelectList.length != 0) {
                this.checkSelectList.map(item => {
                    data.push(tableColumnsList[item])
                })
            }
            return data
        },
        //  修改详情
        alterDetails(userId, custGlobalId, familyId, familyMemberId, orgCode) {
            this.$router.push({
                path: `/linehomeaddform?isEdit=true&userId=${userId}&custGlobalId=${custGlobalId}&familyId=${familyId}&familyMemberId=${familyMemberId}&orgCode=${orgCode}`,
                query: { isLineHome: this.$route.query.isLineHome ? this.$route.query.isLineHome : "", page: this.$route.query.page ? this.$route.query.page : "" },
            })
        },

        // 改变了查看的查询复选框的回调事件
        changeCheck(val) {
            localStorage.setItem(this.sessionName, val)
            this.talbeColumns = this.getTableColumns()
        },
        // 导出
        saveOperation(id, name) {
            this.$Modal.confirm({
                title: "温馨提示",
                loading: true,
                content: "确认导出当前信息",
                onOk: () => {
                    this.$post(
                        "/syaa/api/syuser/app/residentArchive/exportUserArchive",
                        {
                            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            shareStatus: 2,
                            templateId: id,
                            templateName: name,
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        if (res.code == 200) {
                            this.$Modal.remove()
                            if (res.data) {
                                if (res.data.slice(0, 4) == "http" && res.data.slice(0, 5) !== "https") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }

                            let link = document.createElement("a")
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            link.download = "导出信息"
                            document.body.removeChild(link)
                            this.$Message.destroy()
                            this.templateStatus = false
                        } else {
                            this.$Modal.remove()
                            this.$Message.success({
                                content: "导出失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        operationStatus(status) {
            this.operationModal = status
        },
        // 点击操作记录按钮
        operationRecord() {
            this.operationModal = true
            ++this.isShow
        },
        // 点击新增居民按钮
        addForm() {
            this.$router.push("/linehomeaddform?isEdit=false&orgCode=")
        },
        // 点击其他地方
        changeOther() {
            if (this.isBlock) {
                this.isBlock = !this.isBlock
            }
        },
        // 审核按钮
        rePass(row) {
            let data = {
                familyMemberId: row.familyMemberId,
                status: 2,
            }
            this.updatePass(data)
        },
        // 驳回按钮
        noPass(row) {
            let data = {
                familyMemberId: row.familyMemberId,
                status: 3,
            }
            this.updatePass(data)
        },
        down() {
            this.jectionToggle = false
        },
        updatePass(data) {
            // console.log(data);
            if (data.status === 2) {
                //审核确定
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>是否确定操作</p>",
                    cancelText: "取消",
                    okText: "确认",
                    onOk: () => {
                        this.$post("/syaa/pc/sy/user/online/home/changeSyFamilyMember", {
                            reviewId: parent.vue.loginInfo.userinfo.custGlobalId,
                            reviewer: parent.vue.loginInfo.userinfo.realName,
                            familyMemberId: data.familyMemberId,
                            result: "",
                            status: data.status,
                        }).then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    content: "操作成功",
                                    background: true,
                                })
                                let params = {
                                    pageSize: this.pageSize,
                                    pageNum: this.pageNum,
                                }
                                this.getList(params)
                                return
                            } else {
                                this.$Message.error({
                                    content: res.desc,
                                    background: true,
                                })
                                return
                            }
                        })
                    },
                })
            } else if (data.status === 3) {
                this.jectionToggle = true
                this.updataPassObj = data
            }
            return
        },
        unteData(text) {
            //驳回确定
            if (text.trim().length > 0) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>是否确定操作</p>",
                    cancelText: "取消",
                    okText: "确认",
                    onOk: () => {
                        this.$post("/syaa/pc/sy/user/online/home/changeSyFamilyMember", {
                            reviewId: parent.vue.loginInfo.userinfo.custGlobalId,
                            reviewer: parent.vue.loginInfo.userinfo.realName,
                            familyMemberId: this.updataPassObj.familyMemberId,
                            result: text.trim(),
                            status: this.updataPassObj.status,
                            reason: "",
                        }).then(res => {
                            if (res.code == 200) {
                                this.$Message.success({
                                    content: "操作成功",
                                    background: true,
                                })
                                let params = {
                                    pageSize: this.pageSize,
                                    pageNum: this.pageNum,
                                }
                                this.down()
                                this.$refs.modelValue1.save()
                                this.getList(params)
                                return
                            } else {
                                this.$Message.error({
                                    content: res.desc,
                                    background: true,
                                })
                                return
                            }
                        })
                    },
                })
            } else {
                this.$Message.warning({
                    background: true,
                    content: "请输入驳回原因",
                })
            }
        },

        // 点击新建
        addTemplate() {
            this.addTemplateStatus = true
        },
        changeAddTemplateStatus(status) {
            this.addTemplateStatus = status
        },

        //保存
        saveTemplate() {
            if (!this.templateName) {
                this.$Message.warning({
                    content: "请填写模板名称",
                    background: true,
                })
                return
            }
            let fieldIdArr = []
            this.templateField.forEach(e => {
                e.authFieldList.forEach(res => {
                    if (res.status) {
                        fieldIdArr.push(res.fieldId)
                    }
                })
            })
            if (fieldIdArr.length == 0) {
                this.$Message.warning({
                    content: "请填选择导出字段",
                    background: true,
                })
                return
            }
            let mustList = []
            this.templateField[0].authFieldList.forEach(e => {
                if (e.status) {
                    mustList.push(e)
                }
            })
            if (mustList.length == 0) {
                this.$Message.warning({
                    content: "请勾选人员基本信息字段",
                    background: true,
                })
                return
            }
            this.$post(
                "/syaa/api/syuser/pc/residentArchive/createExportTemp",
                {
                    custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    fieldIdList: fieldIdArr,
                    shareStatus: 2,
                    templateName: this.templateName,
                },
                { "Content-Type": "application/json" }
            ).then(res => {
                if (res.code == 430003) {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
                if (res.code == 200) {
                    this.addTemplateStatus = false
                    this.templateName = ""
                    // this.templateField = this.templateFieldList
                    this.resetStatus()
                    this.getTemplate()
                }
            })
        },
        //保存并导出
        saveAndExport() {
            if (!this.templateName) {
                this.$Message.warning({
                    content: "请填写模板名称",
                    background: true,
                })
                return
            }
            let fieldIdArr = []
            this.templateField.forEach(e => {
                e.authFieldList.forEach(res => {
                    if (res.status) {
                        fieldIdArr.push(res.fieldId)
                    }
                })
            })
            if (fieldIdArr.length == 0) {
                this.$Message.warning({
                    content: "请填选择导出字段",
                    background: true,
                })
                return
            }
            let mustList = []
            this.templateField[0].authFieldList.forEach(e => {
                if (e.status) {
                    mustList.push(e)
                }
            })
            if (mustList.length == 0) {
                this.$Message.warning({
                    content: "请勾选人员基本信息字段",
                    background: true,
                })
                return
            }
            this.$Modal.confirm({
                loading: true,
                title: "温馨提示",
                content: "确认导出当前信息",
                onOk: () => {
                    // this.$post('syaa/api/syuser/pc/residentArchive/createExportTemp',
                    //   {
                    //     custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                    //     fieldIdList:fieldIdArr,
                    //     shareStatus: 2,
                    //     templateName: this.templateName
                    //   },{"Content-Type": "application/json"}
                    // ).then(res => {
                    //   if(res.code == 430003){
                    //     this.$Message.error({
                    //       content:res.desc,
                    //       background:true
                    //     })
                    //     return
                    //   }
                    //   if(res.code == 200){

                    //   }
                    // })
                    this.$post(
                        "/syaa/api/syuser/app/residentArchive/exportUserArchive",
                        {
                            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            shareStatus: 2,
                            fieldIdList: fieldIdArr,
                            templateName: this.templateName,
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        if (res.code == 500) {
                            this.$Modal.remove()
                            this.$Message.error({
                                content: res.desc,
                                background: true,
                            })
                            return
                        }
                        if (res.code == 200) {
                            this.$Modal.remove()
                            if (res.data) {
                                if (res.data.slice(0, 4) == "http" && res.data.slice(0, 5) !== "https") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }

                            let link = document.createElement("a")
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            link.download = "导出信息"
                            document.body.removeChild(link)
                            this.$Message.destroy()
                            this.templateStatus = false
                            this.addTemplateStatus = false
                            this.templateName = ""
                            // this.templateField = this.templateFieldList
                            this.resetStatus()
                            this.getTemplate()
                        }
                    })
                },
            })
        },
        resetStatus() {
            let arr = this.templateField
            arr.forEach(e => {
                e.authFieldList.forEach(res => {
                    res.status = false
                })
            })
            this.templateField = arr
        },

        cancelAdd() {
            this.addTemplateStatus = false
        },
        //获取导出模板
        getTemplate() {
            this.$get("/syaa/api/syuser/pc/residentArchive/selectExportTemp").then(res => {
                if (res.code == 200) {
                    this.templateList = res.dataList
                }
            })
        },
        //获取模板字段
        getField() {
            this.$get("/syaa/api/syuser/pc/residentArchive/selectExportField").then(res => {
                if (res.code == 200) {
                    let arr = res.dataList
                    arr.forEach(e => {
                        e.authFieldList.forEach(res => {
                            res.status = false
                        })
                    })
                    arr.map(e => {
                        switch (e.formId) {
                            case "1":
                                e.formName = "人员基本信息"
                                break
                            case "2":
                                e.formName = "人员详情信息"
                                break
                            case "3":
                                e.formName = "网上家园信息"
                                break
                            case "4":
                                e.formName = "党员信息"
                                break
                            case "5":
                                e.formName = "志愿者信息"
                                break
                            case "6":
                                e.formName = "居民小组信息"
                                break
                            case "7":
                                e.formName = "健康需求评估"
                                break
                            case "8":
                                e.formName = "特殊困难群体信息"
                                break
                            case "9":
                                e.formName = "低保申请信息"
                                break
                            case "10":
                                e.formName = "孤独老人信息"
                                break
                            case "11":
                                e.formName = "残疾人信息"
                                break
                            case "12":
                                e.formName = "退役军职信息"
                                break
                            case "13":
                                e.formName = "疫情防控档案信息"
                                break
                        }
                    })
                    let arr2 = arr.sort(function (a, b) {
                        return a.formId - b.formId
                    })

                    this.templateField = arr2
                    this.templateFieldList = arr2
                }
            })
        },
        changeStatus() {},
        // 删除导出模板
        deleteTemplate(data) {
            this.$Modal.confirm({
                title: "温馨提示",
                loading: true,
                content: `是否删除 <span style='color:red'>${data.templateName}</span> 导出模板`,
                onOk: () => {
                    this.$get("/syaa/api/syuser/pc/residentArchive/deleteExportTemp", {
                        templateId: data.templateId,
                    }).then(res => {
                        if (res.code == 200) {
                            this.$Message.success({
                                content: "删除模板成功",
                                background: true,
                            })
                            this.$Modal.remove()
                            this.getTemplate()
                        } else {
                            this.$Modal.remove()
                            this.$Message.error({
                                content: "删除模板失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },
        //导出excel
        exportExcel() {
            this.$Message.warning({
                content:'系统升级暂不支持导出',
                background:true
            })
            return 
            this.templateStatus = true

            //    this.$Modal.confirm({
            //     title: "温馨提示",
            //     content: "确认导出当前信息",
            //     onOk: () => {
            //     let data = this.importRow
            //     if (data) {
            //       if (data.communityCode) {
            //         this.treeParams = {
            //           ...data,
            //           status: this.searchData.status, //必填
            //           name: this.searchData.name,
            //           mobile: this.searchData.phone,
            //           oemCode: parent.vue.oemInfo.oemCode,
            //           operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
            //           orgCode: parent.vue.loginInfo.userinfo.orgCode,
            //           provinceCode: "",
            //           cityCode: "",
            //           countyCode: "",
            //           streetCode: "",
            //           buildingCode:''
            //         };
            //       }
            //   } else {
            //     this.treeParams = {
            //       communityCode: this.treeParams.communityCode,
            //       roadCode: this.treeParams.roadCode,
            //       status: this.searchData.status, //必填
            //       name: this.searchData.name,
            //       mobile: this.searchData.phone,
            //       houseNo: this.searchData.houseCode,
            //       userType: this.searchData.userType,
            //       wholeAddr: this.searchData.wholeAddr,
            //       contact: this.searchData.contact,
            //       oemCode: parent.vue.oemInfo.oemCode,
            //     };
            //   }
            //       this.$get(
            //         "/syaa/pc/sy/user/online/home/downloadFamilyMember",
            //         {
            //           ...this.treeParams
            //         }
            //       ).then((res) => {
            // if (res.code == 200) {
            //   if (res.data) {
            //     if (
            //       res.data.slice(0, 4) == "http" &&
            //       res.data.slice(0, 5) !== "https"
            //     ) {
            //       res.data = res.data.replace("http", "https");
            //     }
            //   }
            //   // console.log(res.data);
            //   let link = document.createElement("a");
            //   link.href = res.data;
            //   document.body.appendChild(link);
            //   link.click();
            //   link.download = "导出信息";
            //   document.body.removeChild(link);
            //   this.$Message.destroy();
            // } else {
            //   this.$Message.warning({
            //     content: res.desc,
            //     background: true,
            //   });
            // }
            //       });
            //     },
            //   });
        },
        //发消息弹窗状态改变
        sentMsgModal(status) {
            this.msgModal = status
            this.msgform.recIds = ""
            this.msgform.title = ""
            this.msgform.content = ""
            this.selectName = ""
            this.selectId = []
            this.idsList = ""
            this.resTotal = 0
            this.resFee = 0
        },
        //选择接收人弹窗状态改变
        selectStatus(status) {
            this.selectModal = status
        },
        communityCodeFn(data) {
            this.nocommunityCode = data
        },
        async getList(data) {
            // console.log('树节点',data);
            this.overView = {
                orgName: data.orgName,
                orgCode: data.communityCode,
            }

            this.loading = true
            if (data) {
                this.importRow = data

                if (data.communityCode) {
                    this.tableData = []
                    this.pageNum = 1
                    this.pageSize = this.pageSize
                    this.treeParams = {
                        ...data,
                        // status: this.searchData.status, //必填
                        name: this.searchData.name,
                        mobile: this.searchData.phone,
                        oemCode: parent.vue.oemInfo.oemCode,
                        page: data.page,
                        pageSize: data.pageSize,
                        operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                        orgCode: parent.vue.loginInfo.userinfo.orgCode,
                        provinceCode: "",
                        cityCode: "",
                        countyCode: "",
                        streetCode: "",
                        buildingCode: "",
                        houseNo: "",
                    }
                    this.pageNum = data.page
                    this.pageSize = data.pageSize
                }
                console.log("this.treeParams1", this.treeParams)
                console.log("this.treeParams2", JSON.parse(JSON.stringify(this.treeParams)))
                console.log('data.projectCode',data.projectCode);
                if (data.page) {
                    // console.log('paol');

                    if (data.regionType == "2" || data.regionType == "3") {
                        this.treeParams.projectCode = ""
                        this.treeParams.buildingCode = data.projectCode
                    }
                    if (data.regionType == 0 || data.regionType == 4) {
                        // console.log('如果为0 4进',data);
                        this.treeParams.projectCode = ""
                        this.treeParams.houseNo = data.projectCode
                    }

                    console.log("this.treeParams3", this.treeParams)
                    console.log('data.projectCode',data.projectCode);
                    console.log("this.treeParams4", JSON.parse(JSON.stringify(this.treeParams)))
                    //切换页面时
                    this.treeParams.page = data.page
                    this.treeParams.pageSize = data.pageSize
                    this.treeParams.name = this.searchData.name
                    this.treeParams.mobile = this.searchData.phone
                    this.treeParams.houseNo = this.searchData.houseNo
                    this.treeParams.userType = this.searchData.userType
                    this.treeParams.wholeAddr = this.searchData.wholeAddr
                    this.treeParams.contact = this.searchData.contact
                }
                if (data.cancelable) {
                    //点击查询时  查询返回第一页的数据
                    this.treeParams.page = 1
                    this.treeParams.name = this.searchData.name
                    this.treeParams.mobile = this.searchData.phone
                    this.treeParams.houseNo = this.searchData.houseNo
                    this.treeParams.userType = this.searchData.userType
                    this.treeParams.wholeAddr = this.searchData.wholeAddr
                    this.treeParams.contact = this.searchData.contact
                    this.pageNum = 1
                }
            } else {
                this.treeParams = {
                    communityCode: this.treeParams.communityCode,
                    roadCode: this.treeParams.roadCode,
                    // status: this.searchData.status, //必填
                    name: this.searchData.name,
                    mobile: this.searchData.phone,
                    houseNo: this.searchData.houseNo,
                    userType: this.searchData.userType,
                    wholeAddr: this.searchData.wholeAddr,
                    contact: this.searchData.contact,
                    oemCode: parent.vue.oemInfo.oemCode,
                    page: this.pageNum,
                    pageSize: this.pageSize,
                }
            }
            // if (this.searchData.status) {
            //   this.treeParams.status = this.searchData.status;
            // } else {
            //   this.treeParams.status = "";
            // }
            if (this.reset) {
                this.treeParams.mobile = ""
                this.treeParams.houseNo = ""
                this.treeParams.userType = ""
                this.treeParams.wholeAddr = ""
                this.treeParams.contact = ""
                this.treeParams.name = ""
                this.reset = false
            }
            // console.log(this.treeParams);
            //待审居民核数量
            this.changeNum++

            await this.$get("/syaa/pc/sy/user/online/home/getFamilyMemberCount", {
                communityCode: this.treeParams.communityCode,
                status: 1,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                operCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                inviterFlag: 1,
            }).then(res => {
                if (res.code == 200) {
                    this.residentNum = res.data
                }
            })

            await this.$get("/syaa/api/syuser/pc/residentArchive/selectStatusCount", {
                staffOrgCode: parent.vue.loginInfo.userinfo.orgCode,
                orgCode: this.treeParams.communityCode,
                syncStatus: 1,
            }).then(res => {
                this.num = res.data
            })
            await this.$get("/syaa/api/syuser/pc/residentArchive/selectArchivesPage", {
                ...this.treeParams,
                hasSensitive:this.hasSensitive
            })
                .then(res => {
                    if (res.code === "200") {
                        this.tableData = res.dataList
                        this.tableData.map(item => {
                            if (item.status == "1") {
                                item.status = "待审核"
                            } else if (item.status == "2") {
                                item.status = "已通过"
                            } else if (item.status == "3") {
                                item.status = "已驳回"
                            } else if (item.status == "4") {
                                item.status = "待确认"
                            } else {
                                item.status = ""
                            }
                            // if(item.contact && item.contact == '1'){
                            //   item.contact = '是'
                            // }else{
                            //   item.contact = '否'
                            // }
                        })
                        this.total = res.maxCount
                        this.pageNum = res.currentPage
                        this.selectQuery = false
                        // this.searchData.name = "";
                        // this.searchData.phone = "";
                        this.searchData.houseNo = ""
                        this.searchData.userType = ""
                        this.searchData.wholeAddr = ""
                        this.searchData.contact = ""
                        this.loading = false
                    } else {
                        this.loading = false
                        this.$Message.error({
                            background: true,
                            content: res.desc,
                        })
                    }
                })
                .catch(err => {
                    // console.log(err);
                    this.loading = false
                })
        },
        changeEye(){
            this.hasSensitive = !this.hasSensitive
            let  data = {
                            pageSize: this.pageSize,
                            pageNum: this.pageNum,
                        }
            this.getList(data)  
        },
        setSelection(selection) {
            this.selection = selection
        },
        rest() {
            this.searchData.phone = ""
            this.searchData.name = ""
            this.searchData.houseNo = ""
            this.searchData.userType = ""
            this.searchData.wholeAddr = ""
            this.searchData.contact = ""
            // this.searchData.status = "";
            this.searchData.houseNo = ""
            let params = {
                pageSize: 1,
                pageNum: 20,
            }
            this.pageNum = 1
            this.reset = true
            this.getList(params)
        },
        getTableData(data) {
            this.tableData = data.dataList
            this.total = data.maxCount
        },
        remove(row) {
            // 为了在删除最后一页的最后一条数据时能成功跳转回最后一页的上一页
            let totalPage = Math.ceil((this.total - 1) / this.pageSize) // 总页数
            this.pageNum = this.pageNum > totalPage ? totalPage : this.pageNum
            this.pageNum = this.pageNum < 1 ? 1 : this.pageNum

            if (row.familyMemberId) {
                this.$Modal.confirm({
                    title: "温馨提示",
                    content: "<p>确认删除数据</p>",
                    cancelText: "取消",
                    okText: "确认",
                    onOk: () => {
                        this.$post("/syaa/pc/sy/user/online/home/delFamilyMember", {
                            familyMemberId: row.familyMemberId,
                        }).then(res => {
                            if (res.code === "200") {
                                this.$Message.success("删除成功")
                                let data = {
                                    pageSize: this.pageSize,
                                    pageNum: this.pageNum,
                                }
                                this.getList(data)
                            } else {
                                this.$Message.error({ content: "删除失败", background: true })
                            }
                        })
                        // .catch(err => console.log(err));
                    },
                })
            }
        },
        sendMsg() {
            $router.push("/emailgroup?name=linehomeindex")
        },
        selectUser() {
            this.selectModal = true
        },
        selectData(data) {
            let temp = []
            let tempId = []
            let ids = []
            data.forEach(item => {
                tempId.push(item.custGlobalId)
                temp.push(item.title)
                ids.push(item.id)
            })
            this.selectName = temp.join(",")
            this.selectId = tempId
            this.idsList = ids
        },

        saveUser() {
            this.communityCode = this.nocommunityCode
            this.selectModal = false
            this.selectId = Array.from(new Set(this.selectId)) // 去重

            this.msgform.recIds = this.selectId.join(",")

            if (this.idsList.length != 0) this.getSmsUser(this.idsList)
        },
        sendOK(name) {
            if (this.idsList.length == 0) {
                this.$Message.warning({ content: "请选择接收人", background: true })
                return
            }
            if (this.msgform.sendChannel == "2") this.idsList = []
            this.$refs[name].validate(valid => {
                if (valid) {
                    this.$post(
                        "/sendmsg/pc/chat/message/createSyMessagePoint",
                        {
                            ...this.msgform,
                            communityCode: this.communityCode,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            smsBusinessCode: 1,
                            staffName: parent.vue.loginInfo.userinfo.realName,
                            memberIds: this.idsList,
                            oemCode: parent.vue.oemInfo.oemCode,
                            recIds: this.msgform.recIds.split(","),
                        },
                        { "Content-Type": "application/json" }
                    )
                        .then(res => {
                            if (res.code === "200") {
                                if (this.msgform.sendChannel == "1") {
                                    this.$Message.success("发送成功")
                                } else {
                                    this.$Message.success("操作成功")
                                }
                                this.msgModal = false
                                this.msgform.recIds = ""
                                this.msgform.title = ""
                                this.msgform.content = ""
                                this.selectName = ""
                                this.selectId = []
                                this.idsList = ""
                                this.resTotal = 0
                                this.resFee = 0
                                this.$refs.selchildren.removeAll()
                            } else {
                                this.$Message.error({ content: "发送失败", background: true })
                                return
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        })
                }
            })
        },
        sendRest() {
            this.msgform.title = ""
            this.msgform.content = ""
        },

        getSmsUser(data) {
            this.$post("/syaa/pc/sy/user/online/home/selectMemberSmsUser", {
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                ids: data.join(","),
            }).then(res => {
                if (res.code == 200) {
                    this.resTotal = res.data.sendTotal
                    this.resFee = res.data.fee
                } else {
                    this.$Message.error({
                        content: res.desc,
                        background: true,
                    })
                    return
                }
            })
        },
    },
}
</script>

<style scoped lang='less'>
/deep/.ivu-drawer-body {
    .ivu-form-item {
        width: 100%;
        margin-bottom: 0;
    }
}
.demo-drawer-footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    background: #fff;
    z-index: 9999;
}
.check-div {
    width: 100%;
    margin: 10px 0;
    // .check-box{
    //   .check-item{

    //   }
    // }
}
.template-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    position: relative;
    .template-add {
        cursor: pointer;
        /deep/.ivu-icon-md-add:before {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
        p {
            position: absolute;
            left: 50%;
            top: 60%;
            line-height: 60px !important;
            transform: translate(-50%, 0);
        }
    }
    .template-box {
        text-align: center;
        margin: 20px;
        width: 260px;
        height: 220px;
        border: 1px solid #999999;
        border-radius: 5px;
        position: relative;

        p {
            &:nth-of-type(1) {
                font-size: 20px;
                line-height: 50px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            &:nth-of-type(2) {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                padding: 0 10px;
                box-sizing: border-box;
            }
            &:nth-of-type(3) {
                position: absolute;
                left: 50%;
                bottom: 5%;
                transform: translate(-50%, 0);
                width: 100%;
            }
        }
    }
}
.field-list {
    li {
        &:nth-of-type(even) {
            background-color: #f8f8f9;
        }
        padding: 5px 0 5px 30px;
        display: flex;
        .lable {
            font-size: 16px;
            font-weight: 700;
            width: 200px;
            text-align: right;
            padding-right: 40px;
            box-sizing: border-box;
        }
        .field {
            display: flex;
            width: 100%;
            flex-wrap: wrap;
            .ivu-checkbox-wrapper {
                width: 230px;
                margin: 3px 0;
            }
        }
    }
}
.export-modal {
    /deep/ #modal_contentarea {
        overflow: visible !important;
        height: 270px;
    }
}
.eye{
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  margin: 0 15px 0 ;
}
// /deep/.toolsbarRight{
//   display: inline-block !important;
// }
</style>
