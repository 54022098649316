<template>
  <LiefengModal
      title="驳回原因"
      :value="value"
      :fullscreen="fullscreen"
      :toggle="toggle"
  >
    <template v-slot:contentarea>
      <div class="container">
        <Input v-model="value17" maxlength="100" show-word-limit type="textarea" placeholder="请输入驳回原因" :rows="10" style="width: 100%;height: 100%;" />
      </div>
    </template>
    <template v-slot:toolsbar>
      <Button type="primary" @click="toggleFn2">取消</Button>
      <Button type="success" style="margin-left: 10px" @click="determine">确定</Button>
    </template>

  </LiefengModal>
</template>

<script lang="js">
import LiefengModal from "@/components/LiefengModal";

export default {
  name: "LiefengModal.vue",
  components: {
    LiefengModal
  },
  props:{
    value:Boolean,
    fullscreen:Boolean,
    toggleFn2:Function,
    toggle:Boolean,
  },
  data() {
    return {
      value17: ''
    }
  },
  methods:{
    determine(){
      this.$emit('unteData',this.value17)
      // this.value17
    },
    save(){
      this.value17=''
    }
  }
}
</script>

<style scoped lang="scss">

</style>
